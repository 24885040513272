<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
            <v-col cols="12" class="mt-3">
                <v-card class="pa-md-5">
                    <v-col cols="12" xs="12" sm="12" md="12" class="mb-3">
                    <div class="d-flex">
                            <v-card class="rounded-l mr-5 blue-grey lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-1 ">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p>TOTAL : {{ cgl1_avg_sub2 ? $store.getters.convertToCurrencyUs(cgl1_avg_sub2) : 0 }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                            <v-card class="rounded-l mr-5 light-green lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-1">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p>TOTAL : {{ cgl1_avg_sub ? $store.getters.convertToCurrencyUs(cgl1_avg_sub) : 0 }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                        <v-card class="rounded-l mr-5 orange lighten-0" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-1">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p>TOTAL : {{ cgl1_avg_now ? $store.getters.convertToCurrencyUs(cgl1_avg_now) : 0 }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
                    <h5 style="font-weight: 900;">CGL 1</h5>
                    <h6 class="cyan-text text-darken-3 m-0">Production Summary (Year to Year)</h6>
                    <div id="container1" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
            <v-col cols="12" class="mt-3">
               <v-card class="pa-md-5">
                <v-col cols="12" xs="12" sm="12" md="12" class="mb-3">
                    <div class="d-flex">
                            <v-card class="rounded-l mr-5 blue-grey lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p>TOTAL : {{ cgl2_avg_sub2 ? $store.getters.convertToCurrencyUs(cgl2_avg_sub2) : 0 }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                            <v-card class="rounded-l mr-5 light-green lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p>TOTAL : {{ cgl2_avg_sub ? $store.getters.convertToCurrencyUs(cgl2_avg_sub) : 0 }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                        <v-card class="rounded-l mr-5 orange lighten-0" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p>TOTAL : {{ cgl2_avg_now ? $store.getters.convertToCurrencyUs(cgl2_avg_now) : 0 }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col> 
                    <h5 style="font-weight: 900;">CGL 2</h5>
                    <h6 class="cyan-text text-darken-3 m-0">Production Summary (Year to Year)</h6>
                    <div id="container2" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
            <v-col cols="12" class="mt-3">
               <v-card class="pa-md-5">
                <v-col cols="12" xs="12" sm="12" md="12" class="mb-3">
                    <div class="d-flex">
                            <v-card class="rounded-l mr-5 blue-grey lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p>TOTAL : {{ cgl_avg_sub2 ? $store.getters.convertToCurrencyUs(cgl_avg_sub2) : 0 }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                            <v-card class="rounded-l mr-5 light-green lighten-1" outlined max-width="100" min-width="160">
                            <v-card-text class="pl-6 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p>TOTAL : {{ cgl_avg_sub ? $store.getters.convertToCurrencyUs(cgl_avg_sub) : 0 }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                        <v-card class="rounded-l mr-5 orange lighten-0" outlined max-width="80" min-width="160">
                            <v-card-text class="pl-4 pr-3 pb-2">
                                <h6 style="font-weight:500;" class="mt-0 pt-0 white--text">
                                    <p>TOTAL : {{ cgl_avg_now ? $store.getters.convertToCurrencyUs(cgl_avg_now) : 0 }}</p>
                                </h6>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
                    <h5 style="font-weight: 900;">CGL 1 + CGL 2</h5>
                    <h6 class="cyan-text text-darken-3 m-0">Production Summary (Year to Year)</h6>
                    <div id="container3" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
            <v-col cols="12" class="mt-3">
                <v-card class="pa-md-5">
                    <h5 style="font-weight: 900;">SUM CGL 1 + CGL 2 RUN MONTHLY</h5>
                    <h6 class="cyan-text text-darken-3 m-0">Production Summary (Year to Year)</h6>
                    <div id="container4" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Dashboard',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Production Summary',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            tab: 'tab-1',
            speed: null,
            cgl1_avg_now:[],
            cgl1_avg_sub:[],
            cgl1_avg_sub2:[],
            cgl2_avg_now:[],
            cgl2_avg_sub:[],
            cgl2_avg_sub2:[],
            cgl_avg_now:[],
            cgl_avg_sub:[],
            cgl_avg_sub2:[],
            dashboardContent: null,
            x: window.matchMedia("(max-width: 991px)"),
            months: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May',
                'Jun', 'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'
            ],
            subyear: '',
            year: '',
            subyear2:''
        }
    },
    mounted(){
        this.getDashboard()
    },
    methods:{
        monthNumToName(monthnum) {
            return this.months[monthnum - 1] || '';
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getChart(dt1, dt2, dt3, dt4, dt5, container){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: [
                    {
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        title: "TON",
                        labelFormatter: this.addSymbols,
                    },
                ],
                axisY2: [{
                    title: "Utilization (%)",
                    axisYType: "secondary"
                }],
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                
                data: [
                {
                    type: "column",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: this.subyear2+ " Production",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#a5a5a5",
                },
                {
                    type: "column",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: this.subyear+ " Production",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#93d14c",
                },
                {
                    type: "column",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: this.year+ " Production",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#ed7d31",
                },
                {
                    type: "line",
                    // indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: this.year+ " Target",
                    showInLegend: true,
                    dataPoints: dt4,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#ed7d31",
                },
                {
                type: "line",
                // indexLabel: "{y}",
                indexLabelFontSize: 14,
                indexLabelFontColor: "#FFF",
                name: "Utilization (%)",
                showInLegend: true,
                dataPoints: dt5,
                indexLabelPlacement: "inside",  
                indexLabelOrientation: "vertical",
                color: "#34D0F7",
                axisYType: "secondary",
                }]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            chart.options.data[3].dataPoints = dt4;
            chart.options.data[4].dataPoints = dt5;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        async getDashboard(){
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/production`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                this.cgl1_avg_now = res.data.total_cgl1_now
                this.cgl1_avg_sub = res.data.total_cgl1_sub
                this.cgl1_avg_sub2 = res.data.total_cgl1_sub2
                this.cgl2_avg_now = res.data.total_cgl2_now
                this.cgl2_avg_sub = res.data.total_cgl2_sub
                this.cgl2_avg_sub2 = res.data.total_cgl2_sub2
                this.cgl_avg_now = res.data.total_cgl1_now + res.data.total_cgl2_now
                this.cgl_avg_sub = res.data.total_cgl1_sub + res.data.total_cgl2_sub
                this.cgl_avg_sub2 = res.data.total_cgl1_sub2 + res.data.total_cgl2_sub2
                this.dashboardContent = res.data
                this.subyear2 = res.data.subyear2
                this.subyear = res.data.subyear
                this.year = res.data.year

                this.getChart(res.data.arr_list3, res.data.arr_list1, res.data.arr_list2, res.data.arr_list4, res.data.arr_list5, "container1");
                this.getChart(res.data.arr_list10, res.data.arr_list9, res.data.arr_list6, res.data.arr_list7, res.data.arr_list8, "container2");
                
                // if (this.dashboardContent.cgl1_now.length > 0  && this.dashboardContent.cgl1_sub.length > 0 && this.dashboardContent.cgl1_sub2.length > 0 && this.dashboardContent.cgl2_now.length > 0  && this.dashboardContent.cgl2_sub.length > 0 && this.dashboardContent.cgl2_sub2.length > 0) {

                    var dt21 = [];
                    var dt22 = [];
                    var dt23 = [];
                    var dt24 = [];
                    var dt25 = [];

                    // if(this.dashboardContent.cgl_nowsumall.length > 0){

                    //     for (var i = 0; i < this.dashboardContent.cgl_nowsumall.length; i++) {
                    //         dt24.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl_nowsumall[i].bulan)), y: parseFloat(this.dashboardContent.cgl_nowsumall[i].target) });
                    //         dt25.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl_nowsumall[i].bulan)), y: parseFloat(this.dashboardContent.cgl_nowsumall[i].utilization) });
                    //     }

                    // }

                    if(this.dashboardContent.cgl2_now.length > 0){

                        for (var i = 0; i < this.dashboardContent.cgl1_now.length; i++) {

                            for (var i = 0; i < this.dashboardContent.cgl2_now.length; i++) {

                                if (this.monthNumToName(parseInt(this.dashboardContent.cgl1_now[i].bulan)) == this.monthNumToName(parseInt(this.dashboardContent.cgl2_now[i].bulan))) {

                                    dt22.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl1_now[i].bulan)), y: parseFloat(this.dashboardContent.cgl1_now[i].total_wgt)+parseFloat(this.dashboardContent.cgl2_now[i].total_wgt)});
                                }

                            }
                        
                        }

                    } else {

                        for (var i = 0; i < 12; i++) {
                            dt22.push({ label: this.monthNumToName([i]), y: 5000});
                        }

                    }

                    console.log(dt22);

                    if(this.dashboardContent.cgl1_sub.length > 0){

                        
                        for (var i = 0; i < this.dashboardContent.cgl1_sub.length; i++) {

                            for (var i = 0; i < this.dashboardContent.cgl2_sub.length; i++) { 

                                if (this.monthNumToName(parseInt(this.dashboardContent.cgl1_sub[i].bulan)) == this.monthNumToName(parseInt(this.dashboardContent.cgl2_sub[i].bulan))) {
                                
                                    dt21.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl1_sub[i].bulan)), y: parseFloat(this.dashboardContent.cgl1_sub[i].total_wgt)+parseFloat(this.dashboardContent.cgl2_sub[i].total_wgt) });
                                    
                                }


                            }
                            
                        }

                    } else {

                        for (var i = 0; i < 12; i++) {
                            dt21.push({ label: this.monthNumToName([i]), y: 5000});
                        }

                    }

                    console.log(dt21);


                    if(this.dashboardContent.cgl1_sub2.length > 0){

                        for (var i = 0; i < this.dashboardContent.cgl1_sub2.length; i++) 
                        {
                            
                            for (var i = 0; i < this.dashboardContent.cgl2_sub2.length; i++) { 

                                if (this.monthNumToName(parseInt(this.dashboardContent.cgl1_sub2[i].bulan)) == this.monthNumToName(parseInt(this.dashboardContent.cgl2_sub2[i].bulan))) {

                                    dt23.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl1_sub2[i].bulan)), y: parseFloat(this.dashboardContent.cgl1_sub2[i].total_wgt)+parseFloat(this.dashboardContent.cgl2_sub2[i].total_wgt) });
                                    
                                }


                            }

                        }

                    } else {

                        for (var i = 0; i < 12; i++) {
                            dt23.push({ label: this.monthNumToName([i]), y: 5000});
                        }

                    }

                    var container = "container3";

                    this.getChart(dt23,dt21,dt22,dt24,dt25, container);

                // }

                // if (this.dashboardContent.cgl1_now.length > 0  && this.dashboardContent.cgl1_sub.length > 0 && this.dashboardContent.cgl1_sub2.length > 0 && this.dashboardContent.cgl2_now.length > 0  && this.dashboardContent.cgl2_sub.length > 0 && this.dashboardContent.cgl2_sub2.length > 0) {
                    var dt26 = [];
                    var dt27 = [];
                    var dt28 = [];

                    for (let index = 0; index < this.dashboardContent.cgl1_now.length; index++) {
                        if (this.monthNumToName(parseInt(this.dashboardContent.cgl1_now[index].bulan)) == this.monthNumToName(parseInt(this.dashboardContent.cgl2_now[index].bulan))) {
                            if (index == 0) {
                                dt26.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl1_now[index].bulan)), y: parseFloat(this.dashboardContent.cgl1_now[index].total_wgt) + parseFloat(this.dashboardContent.cgl2_now[index].total_wgt) });
                            } else {
                                if (this.dashboardContent.cgl1_now[index] && this.dashboardContent.cgl2_now[index]) {
                                    var total_wgt = parseFloat(dt26[index - 1].y) + (parseFloat(this.dashboardContent.cgl1_now[index].total_wgt) + parseFloat(this.dashboardContent.cgl2_now[index].total_wgt))
                                    
                                    dt26.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl1_now[index].bulan)), y: parseFloat(total_wgt) });
                                }                            
                            }
                        }
                    }

                    for (let index = 0; index < this.dashboardContent.cgl1_sub.length; index++) {
                        if (this.monthNumToName(parseInt(this.dashboardContent.cgl1_sub[index].bulan)) == this.monthNumToName(parseInt(this.dashboardContent.cgl2_sub[index].bulan))) {
                            if (index == 0) {
                                dt27.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl1_sub[index].bulan)), y: parseFloat(this.dashboardContent.cgl1_sub[index].total_wgt) + parseFloat(this.dashboardContent.cgl2_sub[index].total_wgt) });
                            } else {
                                if (this.dashboardContent.cgl1_sub[index] && this.dashboardContent.cgl2_sub[index]) {
                                    var total_wgt = parseFloat(dt27[index - 1].y) + (parseFloat(this.dashboardContent.cgl1_sub[index].total_wgt) + parseFloat(this.dashboardContent.cgl2_sub[index].total_wgt))
                                    
                                    dt27.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl1_sub[index].bulan)), y: parseFloat(total_wgt) });
                                }                            
                            }
                        }
                    }

                    for (let index = 0; index < this.dashboardContent.cgl1_sub2.length; index++) {
                        if (this.monthNumToName(parseInt(this.dashboardContent.cgl1_sub2[index].bulan)) == this.monthNumToName(parseInt(this.dashboardContent.cgl2_sub2[index].bulan))) {
                            if (index == 0) {
                                dt28.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl1_sub2[index].bulan)), y: parseFloat(this.dashboardContent.cgl1_sub2[index].total_wgt) + parseFloat(this.dashboardContent.cgl2_sub2[index].total_wgt) });
                            } else {
                                if (this.dashboardContent.cgl1_sub2[index] && this.dashboardContent.cgl2_sub2[index]) {
                                    var total_wgt = parseFloat(dt28[index - 1].y) + (parseFloat(this.dashboardContent.cgl1_sub2[index].total_wgt) + parseFloat(this.dashboardContent.cgl2_sub2[index].total_wgt))
                                    
                                    dt28.push({ label: this.monthNumToName(parseInt(this.dashboardContent.cgl1_sub2[index].bulan)), y: parseFloat(total_wgt) });
                                }                            
                            }
                        }
                    }

                    var container = "container4";

                    this.getChart2(dt26, dt27, dt28, container)

                // }

                this.$store.dispatch('setOverlay', false)

            })
        },
        getChart2(dt1, dt2, dt3, container){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: [
                    {
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        title: "TON",
                        labelFormatter: this.addSymbols,
                    },
                ],
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                
                data: [
                {
                    type: "column",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: this.subyear2+ " Production",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#a5a5a5",
                },
                {
                    type: "column",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: this.subyear+ " Production",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#93d14c",
                },
                {
                    type: "column",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: this.year+ " Production",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#ed7d31",
                }
            ]
            });

            chart.options.data[0].dataPoints = dt3;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt1;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
    },
  }
</script>